
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";

const SystemModule = namespace("system");

@Options({
  components: {},
})
export default class Recruit extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);
  }

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  private async routerView(to: string) {
    await this.updatePageLoadingState(true);
    this.$router.push(to);
  }

  recruitInfo = {
    sales: {
      title: "新規開拓営業",
      details: "新規の個人のお客様や不動産会社様に対して、営業戦略の構築をリードする役割を主にお任せします。\n年齢問わず、能力次第でいくらでも伸びることができることも魅力の一つです。"
    },
    engineer: {
      title: "造園作業員",
      details: "主に築庭、樹木の選定、庭園や花壇の手入れといった造園業務を担当していただきます。\n試用期間は補助作業員として造園業務を覚えてもらい、期間後は新規の顧客を個別に担当していただきます。"
    }
  }
}
