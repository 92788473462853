<template>
  <div class="recruit">
    <div style="padding: 3rem 0rem 0rem">
      <div class="container is-widescreen" style="padding: 3rem 1.5rem 3rem">
        <div
          style="
            font-weight: 700;
            font-size: 1.75em;
            text-align: center;
            color: #2e3192;
            padding: 1.5rem;
          "
        >
          <div>採用情報</div>
        </div>
        <div style="font-size: 1rem">
          <div>
            より良いリフォーム・リノベーションを目指して、私たちと一緒に働きませんか？
          </div>
          <div>
            株式会社if
            では、学歴・経験・年齢を問わず、意欲あふれる方をお待ちしています。
          </div>
        </div>
        <div class="recruit-table-content">
          <div
            style="
              background-color: #f5f5f5;
              width: 100%;
              border-bottom: 1px solid #2e319279;
              padding: 10px;
              font-weight: bold;
            "
          >
            パート・アルバイト採用
          </div>
          <table>
            <tr>
              <th scope="row">造園補助作業員</th>
              <td>造園スタッフのアシスタントを担当していただきます。</td>
            </tr>
            <tr>
              <th scope="row">清掃作業員</th>
              <td>清掃スタッフのアシスタントを担当していただきます。</td>
            </tr>
            <tr>
              <th scope="row">想定給与</th>
              <td>９００円〜</td>
            </tr>
            <tr>
              <th scope="row">勤務時間</th>
              <td>9:00〜18:00（左記時間のうち、１日６時間以上）</td>
            </tr>
            <tr>
              <th scope="row">試用期間</th>
              <td>３ヶ月</td>
            </tr>
            <tr>
              <th scope="row">応募条件</th>
              <td>
                <div>未経験の方も大歓迎</div>
                <div>平日週２、３日以上での勤務が可能な方</div>
                <div>普通自動車免許(AT限定も可)をお持ちの方優遇</div>
                <div>対象業務に経験のある方優遇</div>
              </td>
            </tr>
            <tr>
              <th scope="row">優遇</th>
              <td>
                <div>交通費支給（上限１０,０００円/月）</div>
                <div>各種資格（相談に応じて手当の支給あり）</div>
              </td>
            </tr>
            <tr>
              <th scope="row">勤務地</th>
              <td>
                <div>長野県須坂市須坂１２９８-１３</div>
                <div>長野電鉄須坂駅より徒歩１分</div>
              </td>
            </tr>
          </table>
        </div>
        <div class="recruit-table-content">
          <div
            style="
              background-color: #f5f5f5;
              width: 100%;
              border-bottom: 1px solid #2e319279;
              padding: 10px;
              font-weight: bold;
            "
          >
            正社員・中途採用（技術職）
          </div>
          <table>
            <tr>
              <th scope="row">造園作業員</th>
              <td style="white-space: pre-wrap;word-wrap:break-word;">{{recruitInfo.engineer.details}}</td>
            </tr>
            <tr>
              <th scope="row">基本給与</th>
              <td>１８０,０００円〜</td>
            </tr>
            <tr>
              <th scope="row">勤務時間</th>
              <td>9:00〜18:00</td>
            </tr>
            <tr>
              <th scope="row">休日</th>
              <td>
                <div>日曜・祝日は定休日</div>
                <div>※有給休暇制度あり</div>
              </td>
            </tr>
            <tr>
              <th scope="row">試用期間</th>
              <td>３ヶ月</td>
            </tr>
            <tr>
              <th scope="row">応募条件</th>
              <td>
                <div>未経験の方も大歓迎</div>
                <div>普通自動車免許(AT限定も可)をお持ちの方(必須)</div>
                <div>対象業務に経験のある方優遇</div>
              </td>
            </tr>
            <tr>
              <th scope="row">優遇</th>
              <td>
                <div>交通費支給（上限１０,０００円/月）</div>
                <div>
                  各種保険（健康保険、雇用保険、労災保険、厚生年金保険）
                </div>
                <div>各種資格（相談に応じて手当の支給あり）</div>
              </td>
            </tr>
            <tr>
              <th scope="row">勤務地</th>
              <td>
                <div>長野県須坂市須坂１２９８-１３</div>
                <div>長野電鉄須坂駅より徒歩１分</div>
              </td>
            </tr>
          </table>
        </div>
        <div class="recruit-table-content">
          <div
            style="
              background-color: #f5f5f5;
              width: 100%;
              border-bottom: 1px solid #2e319279;
              padding: 10px;
              font-weight: bold;
            "
          >
            正社員・中途採用（営業職）
          </div>
          <table>
            <tr>
              <th scope="row">新規開拓営業</th>
              <td style="white-space: pre-wrap;word-wrap:break-word;">{{recruitInfo.sales.details}}</td>
            </tr>
            <tr>
              <th scope="row">基本給与</th>
              <td>
                <div>１６０,０００円〜</div>
                <div>＋出来高に応じて変動</div>
                <div>（試用期間中は１８０,０００円〜）</div>
              </td>
            </tr>
            <tr>
              <th scope="row">勤務時間</th>
              <td>9:00〜18:00</td>
            </tr>
            <tr>
              <th scope="row">休日</th>
              <td>
                <div>日曜・祝日は定休日</div>
                <div>※週休は任意で2日取得</div>
                <div>※有給休暇制度あり</div>
              </td>
            </tr>
            <tr>
              <th scope="row">試用期間</th>
              <td>３ヶ月</td>
            </tr>
            <tr>
              <th scope="row">応募条件</th>
              <td>
                <div>未経験の方も大歓迎</div>
                <div>普通自動車免許(AT限定も可)をお持ちの方(必須)</div>
                <div>新規開拓営業に経験のある方優遇</div>
              </td>
            </tr>
            <tr>
              <th scope="row">優遇</th>
              <td>
                <div>交通費支給（上限１０,０００円/月）</div>
                <div>
                  各種保険（健康保険、雇用保険、労災保険、厚生年金保険）
                </div>
                <div>各種資格（相談に応じて手当の支給あり）</div>
              </td>
            </tr>
            <tr>
              <th scope="row">勤務地</th>
              <td>
                <div>長野県須坂市須坂１２９８-１３</div>
                <div>長野電鉄須坂駅より徒歩１分</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div
      style="
        text-align: left;
        padding: 30px;
        background-color: #f5f5f5;
        padding: 3rem 0rem 3rem;
      "
    >
      <div class="columns" style="padding: 3rem 1.5rem 3rem">
        <div
          class="column"
          style="font-size: 1.2rem; font-weight: 400; padding: 1.5rem"
        >
          <div>
            ご応募は お電話、または お問い合わせフォームからお願いいたします。
          </div>
          <div>面接までの流れについてご案内させていただきます。</div>
          <div style="padding: 1rem 0rem 1rem">
            <a class="button is-primary" @click="routerView('/contact')"
              >お問い合わせページへ</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";

const SystemModule = namespace("system");

@Options({
  components: {},
})
export default class Recruit extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);
  }

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  private async routerView(to: string) {
    await this.updatePageLoadingState(true);
    this.$router.push(to);
  }

  recruitInfo = {
    sales: {
      title: "新規開拓営業",
      details: "新規の個人のお客様や不動産会社様に対して、営業戦略の構築をリードする役割を主にお任せします。\n年齢問わず、能力次第でいくらでも伸びることができることも魅力の一つです。"
    },
    engineer: {
      title: "造園作業員",
      details: "主に築庭、樹木の選定、庭園や花壇の手入れといった造園業務を担当していただきます。\n試用期間は補助作業員として造園業務を覚えてもらい、期間後は新規の顧客を個別に担当していただきます。"
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/util.scss";

.recruit {
  .recruit-table-content {
    padding: 3rem 0rem 3rem;

    td {
      border-bottom: 1px solid #2e319279;
      padding: 10px;
      width: 70%;
      color: #2e3192;
    }

    th {
      border-bottom: 1px solid #2e319279;
      padding: 10px;
      width: 30%;
      color: #2e3192;
    }

    table {
      // border: 1px solid #2e3192;
      // border-collapse: collapse;
      width: 100%;
    }
  }

  @include sp {
    .recruit-table-content {
      padding: 3rem 0rem 3rem;
    }
  }
}
</style>
